<template>
  <div class="page_wyModule">
    <div class="page_wy_content">
      <div class="wy_title">
        <div class="introduce_top">
          <div class="title">
            <div class="line"></div>

            <div class="txt">未来+物业管理系统功能板块</div>

            <div class="line"></div>
          </div>

          <div class="sub_title">物业及社区功能板块</div>
        </div>
      </div>

      <div class="wy_content">
        <div class="content_list" v-for="(item,index) in list" :key="item.title">
          <div class="list_box" @click="goPageModule(index)">
            <div class="img_box">
              <img class="img" :src="item.imgStr" />
            </div>
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="wy_contact_us">
      <div class="title">联系我们，为您专属搭建定制化方案</div>
      <div class="btn" @click="goPageContact()">联系我们</div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      list: [
        {
          imgStr: require("../assets/wy/wy_jiaofei.png"),
          title: "物业缴费",
          desc: "物业缴费，业主更方便缴费,物业更方便收缴,实时管控"
        },
        {
          imgStr: require("../assets/wy/wy_baoxiu.png"),
          title: "报事报修",
          desc: "业主可在手机上直接发起,物业积极响应并安排相应人员解决"
        },
        {
          imgStr: require("../assets/wy/wy_zixun.png"),
          title: "咨询诉求",
          desc: "业主可在手机上直接发起,物业积极给出解决方案"
        },
        {
          imgStr: require("../assets/wy/wy_zhixu.png"),
          title: "秩序安保",
          desc: "业主可在手机上直接发起,物业安排人员维护秩序"
        },
        {
          imgStr: require("../assets/wy/wy_tousu.png"),
          title: "投诉建议",
          desc: "业主可在手机上直接发起,物业针对对应的投诉或者建议,积极整改"
        },
        {
          imgStr: require("../assets/wy/wy_zhenggai.png"),
          title: "督查整改",
          desc: "业主可在手机上直接查询当前投诉或者诉求的进度"
        },
        {
          imgStr: require("../assets/wy/wy_tingche.png"),
          title: "智慧停车",
          desc: "业主可在手机上查看当前小区的可用停车位"
        },
        {
          imgStr: require("../assets/wy/wy_fengcai.png"),
          title: "物业风采",
          desc: "业主可在手机上查看当前的物业服务案例"
        },
        {
          imgStr: require("../assets/wy/wy_fangke.png"),
          title: "访客邀约",
          desc: "业主可在手机上直接发起报事报修"
        },
        {
          imgStr: require("../assets/wy/wy_toupiao.png"),
          title: "服务投票",
          desc: "业主可在手机上直接对物业的服务继续投票"
        },
        {
          imgStr: require("../assets/wy/wy_myWy.png"),
          title: "我的物业",
          desc: "业主可在手机上查看当前小区的物业服务"
        },
        {
          imgStr: require("../assets/wy/wy_gongshi.png"),
          title: "公示栏",
          desc: "业主可在手机上查看当前小区重要的公示信息"
        },
        {
          imgStr: require("../assets/wy/wy_yeweihui.png"),
          title: "业委会",
          desc: "业主可在手机上查看业委会成员"
        },
        {
          imgStr: require("../assets/wy/wy_wyfen.png"),
          title: "物业分",
          desc: "业主可在手机上查询当前拥有的物业分(可抵扣物业费)"
        },
        {
          imgStr: require("../assets/wy/wy_banshidating.png"),
          title: "办事大厅",
          desc: "业主可在手机上直接办理各种证件"
        },
        {
          imgStr: require("../assets/wy/wy_yuqing.png"),
          title: "舆情监管",
          desc: "业主可在手机上查看当前的社会舆情"
        },

        {
          imgStr: require("../assets/wy/wy_sheqinggonggao.png"),
          title: "社情公告",
          desc: "业主可在手机上查看当前社会重大事件公告"
        },
        {
          imgStr: require("../assets/wy/wy_huiminzhengce.png"),
          title: "惠民政策",
          desc: "业主可在手机上查看当前的惠民政策"
        },
        {
          imgStr: require("../assets/wy/wy_shequhuodong.png"),
          title: "社区活动",
          desc: "业主可在手机上查看当前社区举办的各项活动"
        },
        {
          imgStr: require("../assets/wy/wy_dangqun.png"),
          title: "党群服务",
          desc: "业主可在手机上查看党群服务"
        },
        {
          imgStr: require("../assets/wy/wy_sheshizixun.png"),
          title: "社事咨询",
          desc: "业主可在手机上针对当前社情进行咨询,物业积极做出回应"
        },
        {
          imgStr: require("../assets/wy/wy_wangge.png"),
          title: "网格划分",
          desc: "业主可在手机上查看社区的网格划分情况"
        },
        {
          imgStr: require("../assets/wy/wy_dingzhi.png"),
          title: "定制需求",
          desc: "业主可在手机上针对物业服务进行个性化需求定制"
        },
        {
          imgStr: require("../assets/wy/wy_qita.png"),
          title: "其他功能",
          desc: "业主可在手机上查看物业其他功能"
        }
      ]
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    goPageContact() {
      this.$router.push({
        path: "/contact"
      });
    },
    goPageModule(index) {
      if (index == 0) {
        this.$router.push({//物业缴费
          path: "/Payment"
        });
      }else if(index == 1){//报事报修
        this.$router.push({
          path: "/Repair"
        });
      }else if(index == 6){//物业风采
        this.$router.push({
          path: "/media"
        });
      }
    }
  },
  watch: {}
};
</script>
  <style scoped lang="less">
.page_wyModule {
  width: 100%;
  margin: auto;

  .page_wy_content {
    width: 80%;
    margin: auto;
    .wy_title {
      margin-top: 66px;
      .introduce_top {
        .title {
          display: flex;
          align-items: center;
          justify-content: center;

          .txt {
            // width: 142px;
            height: 31px;
            line-height: 31px;
            font-size: 32px;
            padding-left: 15px;
            padding-right: 15px;
            font-weight: bold;
            color: #333333;
          }

          .line {
            width: 48px;
            height: 2px;
            background-color: #333333;
          }
        }
        .sub_title {
          margin-top: 24px;
          font-size: 16px;
          font-weight: 400;
          color: #999;
        }
      }
    }

    .wy_content {
      width: 100%;
      margin: auto;
      margin-top: 150px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .content_list {
        display: inline-block;
        width: 25%;
        margin-bottom: 60px;
        text-align: center;

        .list_box {
          width: 90%;

          .img_box {
            margin: auto;
            width: 180px;
            height: 180px;

            .img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            margin-top: 26px;
            height: 20px;
            font-size: 20px;

            font-weight: 400;
            color: #333333;
          }
          .desc {
            width: 180px;
            margin: auto;
            margin-top: 10px;
            //   height: 40px;
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            color: #5f5f5f;
            line-height: 20px;

            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -moz-box;
            -moz-line-clamp: 2;
            -moz-box-orient: vertical;
            overflow-wrap: break-word;
            word-break: break-all;
            white-space: normal;
            overflow: hidden;
          }
        }
      }
    }
  }

  .wy_contact_us {
    width: 100%;
    margin-top: 240px;
    padding-top: 44px;
    padding-bottom: 44px;
    background: #3ca35f;
    .title {
      height: 20px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
    }
    .btn {
      margin: auto;
      margin-top: 58px;
      width: 193px;
      height: 55px;
      background: #3ca35f;
      border: 1px solid #ffffff;
      border-radius: 8px;
      color: #fff;
      line-height: 55px;
    }
  }
}
</style>
  